import { Injectable, ErrorHandler } from '@angular/core';
import { AzureMonitoringService } from './logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  lastError: string;

  constructor(
    private azureMonitoringService: AzureMonitoringService,
    private snackbar: MatSnackBar) {
        super();
    }

    handleError(error: Error) {

      let errorMessage = 'Unknown error!';
      if (error) {
        errorMessage = `${error.toString()}`;
      }

      if (errorMessage && errorMessage != this.lastError
        && errorMessage.toLowerCase() != 'insufficient rights.'
        && errorMessage.toLowerCase() != 'invalid token'
        && errorMessage.toLowerCase() != 'token not found'
        && errorMessage.toLowerCase() != 'ok'
        && errorMessage.toLowerCase() != 'refresh token error') {
        this.lastError = errorMessage;
        this.snackbar.open(errorMessage, 'DISMISS', { duration: 10000 })
      }
      this.azureMonitoringService.logException(error); 
    }
}
