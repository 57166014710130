import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from './reports.service';
import { ReportData } from './report.model';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {

  report: any;
  isReady: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public reportData: ReportData,
    private dialogRef: MatDialogRef<ReportViewerComponent>,
    private _reportsService: ReportsService,
    private _fileSaverService: FileSaverService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.refreshReport();
  }

  refreshReport() {
    this._reportsService.getReport(this.reportData).subscribe(reportPDF => {
      this.report = reportPDF;
      this.isReady = true;
    });
  }

  downloadExcel() {
    this._reportsService.getReportExcel(this.reportData).subscribe(
      blob => this._fileSaverService.save(blob, this.reportData.reportName + '.xlsx')
  )}

  //downloadFile(data: BlobPart) {
  //  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //  const url = window.URL.createObjectURL(blob);
  //  window.open(url);
  //}

  close() {
    this.dialogRef.close();
  }
}
