import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ThemeService } from '../../../@fury/services/theme.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();

  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));

  constructor(private themeService: ThemeService,
    public _authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  userLogout() {
    this._authenticationService.logout();
  }

  //openJobRequests() {
  //  this.router.navigate(['/job-request'], { queryParams: { returnUrl: this.router.url } });
  //}
}
