import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AzureMonitoringService } from '../_services/logging.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    public router: Router,
    private splashScreenService: SplashScreenService) {

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.sidenavService.addItems([
      {
        name: 'DCT',
        position: 2,
        type: 'subheading',
        customClass: 'first-subheading'
      },
      {
        name: 'Dashboard',
        routeOrFunction: '/dashboard',
        icon: 'dashboard',
        position: 3,
        filter: 'isInternal',
      },
      {
        name: 'Surveys',
        routeOrFunction: '/surveys',
        icon: 'ballot',
        position: 4,
        filter: 'isInternal',
      },  
      {
        name: 'LEAs',
        routeOrFunction: '/leas',
        icon: 'business',
        position: 6,
        filter: 'isInternal',
      },
      {
        name: 'Users',
        routeOrFunction: '/users',
        icon: 'group',
        position: 7,
        filter: 'isInternal',
      },
      {
        name: 'Current Survey',
        routeOrFunction: '/current-survey',
        icon: 'ballot',
        position: 8,
        filter: 'isCOE',
      },
      {
        name: 'System Tables',
        icon: 'table_rows',
        position: 11,
        filter: 'isInternal',
        subItems: [
          {
            name: 'Email Configuration',
            routeOrFunction: '/system-tables/email-configuration',
            position: 10
          },
          {
            name: 'Fiscal Years',
            routeOrFunction: '/system-tables/fiscal-years',
            position: 11
          }
        ]
      }
    ]);
  }
}
