<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications"
     style="display: flex; flex-direction: row;" >
  <button (click)="openInvoicesNeedingAttention()" [class.open]="isOpen" class="button"
          mat-button type="button" matTooltip="{{tooltip}}">
    <mat-icon [matBadgeHidden]="numNotifications === 0" [matBadge]="numNotifications" matBadgeColor="accent" class="icon">
      notifications_active
    </mat-icon>
  </button>

  <div [class.open]="isOpen" class="dropdown">
    <fury-card>
      <fury-card-header class="dropdown-header">
        <fury-card-header-heading>
          Notifications
        </fury-card-header-heading>
        <fury-card-header-subheading>
          You have {{ numNotifications }} new notifications.
        </fury-card-header-subheading>

        <fury-card-header-actions>
          <button mat-icon-button type="button">
            <mat-icon class="icon">settings</mat-icon>
          </button>
        </fury-card-header-actions>
      </fury-card-header>

      <fury-card-content class="dropdown-card-content">
        <fury-scrollbar class="dropdown-content">
          <div style="display: flex; flex-direction: column;">
            <ng-container *ngFor="let notification of notifications; let last = last">
              <div (click)="markAsRead(notification)"
                   [@listFade]
                   [class.read]="notification.read" [ngClass]="notification.colorClass"
                   class="notification" style="display: flex; flex-direction: row;"
                   matRipple>
                <mat-icon class="icon">{{ notification.icon }}</mat-icon>
                <div class="label" style="display: flex; flex-direction: column;">
                  <div class="name">{{ notification.name }}</div>
                  <div class="time">{{ notification.time }}</div>
                </div>
                <span style="flex; flex:1;"></span>
                <button (click)="dismiss(notification, $event)" mat-icon-button type="button">
                  <mat-icon class="close">close</mat-icon>
                </button>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </div>
          <div *ngIf="notifications.length === 0" [@listFade] class="empty">
            All fine! No notifications right now.
          </div>
        </fury-scrollbar>
      </fury-card-content>


      <fury-card-actions (click)="markAllAsRead()" class="dropdown-footer" matRipple>
        Mark all as read
      </fury-card-actions>
    </fury-card>
  </div>
</div>
