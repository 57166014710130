import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from '../_helpers/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/authentication/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'change-password-force',
    loadChildren: () => import('./pages/authentication/change-password-force/change-password-force.module').then(m => m.ChangePasswordForceModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'current-survey',
        loadChildren: () => import('./pages/survey-response/survey-response.module').then(m => m.SurveyResponseModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users/:id',
        loadChildren: () => import('./pages/users/user-detail/user-detail.module').then(m => m.UserDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/user-list/user-list.module').then(m => m.UserListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'leas/:id',
        loadChildren: () => import('./pages/leas/lea-detail/lea-detail.module').then(m => m.LeaDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'leas',
        loadChildren: () => import('./pages/leas/lea-list/lea-list.module').then(m => m.LeaListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'survey-history/:id/:coeId',
        loadChildren: () => import('./pages/surveys/survey-response-history/survey-response-history.module').then(m => m.SurveyResponseHistoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'surveys/:id/:tab',
        loadChildren: () => import('./pages/surveys/survey-detail/survey-detail.module').then(m => m.SurveyDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'surveys/:id',
        loadChildren: () => import('./pages/surveys/survey-detail/survey-detail.module').then(m => m.SurveyDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'surveys',
        loadChildren: () => import('./pages/surveys/survey-list/survey-list.module').then(m => m.SurveyListModule),
        canActivate: [AuthGuard]
      }, 
      {
        path: 'system-tables/email-configuration',
        loadChildren: () => import('./pages/system-tables/email-configuration/email-configuration.module').then(m => m.EmailConfigurationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'system-tables/fiscal-years',
        loadChildren: () => import('./pages/system-tables/fiscal-years/fiscal-years.module').then(m => m.FiscalYearsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'coming-soon',
        loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
      }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
