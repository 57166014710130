<div class="navigation-item-container">
  <a (click)="handleClick.emit(item)" class="navigation-item"
     style="display: flex; flex-direction: row;"
     matRipple
     routerLinkActive="active">
    <mat-icon class="icon">{{ item.icon }}</mat-icon>
    <span class="name">{{ item.name }}</span>
  </a>
</div>

