import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Survey, SurveyResponse } from '../../../_models/survey.model';

@Injectable()
export class SurveyResponseService {

  constructor(private http: HttpClient) {}
 
  getCurrentSurvey() {
    return this.http.get<Survey>(`${environment.apiUrl}/Survey/GetCurrentSurvey`)
  }

  submitSurvey(response: SurveyResponse) {
    return this.http.post<any>(`${environment.apiUrl}/Survey/SubmitSurveyResponse`, response);
  }
}
