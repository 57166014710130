<div mat-dialog-title>Change My Password</div>

<div class="jms-error-report" *ngIf="hasError()">
  Current Password is invalid
</div>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content style="margin-top: 10px;">
    <div style="display: flex; flex-direction: column; flex: 1;">
      <mat-form-field>
        <mat-label>Old Password</mat-label>
        <input matInput [type]="inputType" formControlName="oldpassword" id="oldpassword" required>
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="Toggle Visibility">
          <mat-icon *ngIf="visible">visibility</mat-icon>
          <mat-icon *ngIf="!visible">visibility_off</mat-icon>
        </button>
        <mat-error *ngIf="form.get('oldpassword').hasError('required')">Please enter your current password</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput [type]="inputType" formControlName="newpassword" id="newpassword" required>
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="Toggle Visibility">
          <mat-icon *ngIf="visible">visibility</mat-icon>
          <mat-icon *ngIf="!visible">visibility_off</mat-icon>
        </button>
        <mat-error *ngIf="form.get('newpassword').hasError('required')">Please enter a new password</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button mat-button (click)="cancel(); $event.preventDefault()">CANCEL</button>
    <button mat-flat-button [disabled]="!form.valid" color="accent">CHANGE PASSWORD</button>
  </mat-dialog-actions>
</form>

