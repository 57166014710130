import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { User } from '../_models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from './lists.service';
import { AzureMonitoringService } from './logging.service';
import { UserSearchParams } from '../app/pages/users/user-list/user-list.searchparams.model';
import { LeaSearchParams } from '../app/pages/leas/lea-list/lea-list.searchparams.model';
import { SurveySearchParams } from '../app/pages/surveys/survey-list/survey-list.searchparams.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private _pollInterval = 10000;
  private _userSubject = new BehaviorSubject<User>(null);
  user$: Observable<User> = this._userSubject.asObservable();

  private _userSearchParams = new BehaviorSubject<UserSearchParams>(null);
  userSearchParams$: Observable<UserSearchParams> = this._userSearchParams.asObservable();

  private _leaSearchParams = new BehaviorSubject<LeaSearchParams>(null);
  leaSearchParams$: Observable<LeaSearchParams> = this._leaSearchParams.asObservable();

  private _surveySearchParams = new BehaviorSubject<SurveySearchParams>(null);
  surveySearchParams$: Observable<SurveySearchParams> = this._surveySearchParams.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _loggingService: AzureMonitoringService,
    public _listService: ListService) { }

  public get currentUserValue(): User {
    return this._userSubject.value;
  }

  setUser(user: User) {
    if (!!user) {
      this._loggingService.setUserId(user.userId.toString());
    } else {
      this._loggingService.clearUserId();
    }

    this._userSubject.next(user);
    this.getAllLists(user);

    if (user && user.isInternal) {
      this.getnotifications();
    }

    if (user && user.forcePasswordChange) {
      this.router.navigate(['/change-password-force']);
    }

    if (user && user.isCOE && user.lastLoginDate) {
      this.router.navigate(['/current-survey']);
    }
  }

  updateUserInfo(user: User) {
    let currentUser = this.currentUserValue;
    currentUser.firstName = user.firstName;
    currentUser.lastName = user.lastName;
    currentUser.title = user.title;
    currentUser.phone = user.phone;
    currentUser.ext = user.ext;
    currentUser.email = user.email;
    this.setUser(currentUser);
  }

  getAllLists(user: User) {
    if (user) {
      this._listService.getLookups();
      this._listService.getLeas(user.coeId);
      this._listService.getFiscalYears();

      if (user.isInternal) {
        this._listService.getUsers();
      }
    }

    this.resetSearchParams();
  }

  public refreshLeas() {
    this._listService.resetLeas();
    this._listService.getLeas(this.currentUserValue.coeId);
  }

  login(username: string, password: string) {
    return this.http.post<User>(`${environment.apiUrl}/user/authenticate`, { username, password }, { withCredentials: true })
      .pipe(map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        
        let currentUser = new User();
        currentUser.insertData(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return currentUser;
      }));
  }

  logout() {
    this.http.post<any>(`${environment.apiUrl}/user/RevokeToken`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.setUser(null);
    this.router.navigate(['/login']);
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/user/RefreshToken`, {}, { withCredentials: true })
      .pipe(map((user) => {
        let currentUser = new User();
        currentUser.insertData(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  getUserRegistrationInfo(jwtToken: string) {
    var tempUser = new User();
    tempUser.jwtToken = jwtToken;
    tempUser.isCreating = true;
    this.setUser(tempUser);

    return this.http.get<User>(`${environment.apiUrl}/User/GetUserRegistrationInfo`)
  }

  register(user: User, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/user/Register`, { user, password })
      .pipe(map((user) => {
        let currentUser = new User();
        currentUser.insertData(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return currentUser;
      }));
  }

  sendResetLink(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/user/sendResetLink`, { email });
  }

  resetPassword(password: string, jwtToken: string) {
    var tempUser = new User();
    tempUser.jwtToken = jwtToken;
    this.setUser(tempUser);
    return this.http.post<any>(`${environment.apiUrl}/user/resetPassword`, { password })
      .pipe(map(user => {
        let currentUser = new User();
        currentUser.insertData(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return currentUser;
      }));
  }

  changePassword(oldpassword: string, newpassword: string,) {
    return this.http.post<any>(`${environment.apiUrl}/user/changePassword`, { oldpassword, newpassword });
  }

  forceChangePassword(oldpassword: string, newpassword: string,) {
    return this.http.post<any>(`${environment.apiUrl}/user/ForceChangePassword`, { oldpassword, newpassword }).pipe(map(user => {
      let currentUser = new User();
      currentUser.insertData(user);
      this.setUser(currentUser);
      this.startRefreshTokenTimer();
      return currentUser;
    }));
  }

  resetUserPassword(userId: number, password: string, forceReset: boolean) {
    var options = { userId: userId, password: password, forceReset: forceReset };
    return this.http.post<any>(`${environment.apiUrl}/user/UpdateUserPassword`, options);
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentUserValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  //search params
  public setUserSearchParams(params: UserSearchParams) {
    this._userSearchParams.next(params);
  }

  public setLeaSearchParams(params: LeaSearchParams) {
    this._leaSearchParams.next(params);
  }

  public setSurveySearchParams(params: SurveySearchParams) {
    this._surveySearchParams.next(params);
  }


  public resetSearchParams(): void {
    const u = new UserSearchParams();
    this._userSearchParams.next(u);

    const l = new LeaSearchParams();
    this._leaSearchParams.next(l);

    const s = new SurveySearchParams();
    this._surveySearchParams.next(s);
  }


  // notifications
  private setUserNotifications(numNotifications: number) {
    //var user = this._userSubject.value;
    //if (user) {
    //  user.numNotifications = numNotifications;
    //  this._userSubject.next(user);
    //}
  }

  public getnotifications() {
    //if (this._userSubject.value && this._userSubject.value.isInternal) {
    //  return this.http.get<any>(`${environment.apiUrl}/user/GetNumInvoicesNeedingAttention`).subscribe(n => {
    //    this.setUserNotifications(n);
    //    setTimeout(() => this.getnotifications(), this._pollInterval);
    //  });
    //}
  }

}
