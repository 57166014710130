import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class DocumentService {

  constructor(private http: HttpClient) {}
  
  removeDocument(id: number) {
    return this.http.post<any>(`${environment.apiUrl}/Document/RemoveDocument`, id);
  }

  getDocument(id: number) {
    let headers = new HttpHeaders();
    let params = new HttpParams().set('id', id.toString());
    headers = headers.set('Accept', 'application/octet-stream');
    headers = headers.set('Content-Disposition', 'attachment');
    return this.http.get(`${environment.apiUrl}/Document/GetDocument`, { headers, params, responseType: 'blob' })
  }
}
