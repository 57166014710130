import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../../../_models/user.model';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {}
 
  getProfile() {
    return this.http.get<User>(`${environment.apiUrl}/User/GetProfile`);
  }

  updateProfile(user: User) {
    return this.http.post<any>(`${environment.apiUrl}/User/AddUpdateUser`, user);
  }
}
