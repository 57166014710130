import { AuthenticationService } from '../../../_services/authentication.service';

export class ReportData {
  reportName: string = '';
  parameters: ReportParameter[] = [];
  options: ReportOption[] = [];
  hasExcel: boolean = false;
  reportHelpers: ReportHelpers = new ReportHelpers();
  authenticationService: AuthenticationService;
}

export class ReportParameter {
  key: string;
  value: any;

  constructor(data) {
    this.key = data.key;
    this.value = data.value;
  }
}

export class ReportOption {
  label: string;
  reportname: string;
  isChecked: boolean = false;
}

export class ReportHelpers {

  public formatDate(dateValue: Date) {
    return dateValue.toLocaleDateString("en-US");
  }

}


export class SurveyResponseReportData extends ReportData {
  surveyId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "SurveyResponsesByCOE"
    rd.parameters = [];
    if (this.surveyId) {
      rd.parameters.push(new ReportParameter({ key: "SurveyId", value: this.surveyId }));
    }
    rd.hasExcel = true;

    let o = new ReportOption();
    o.label = "Expand Districts Detail";
    o.reportname = "SurveyResponsesByCOE_WithDistrictDetail";
    rd.options.push(o)

    return rd;
  }
}

export class BudgetStatusReportData extends ReportData {

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "LEABudgetStatusOverFiscalYears"
    rd.parameters = [];
    rd.hasExcel = true;

    return rd;
  }
}

export class InterimStatusReportData extends ReportData {

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "LEAInterimStatusOverFiscalYears"
    rd.parameters = [];
    rd.hasExcel = true;

    return rd;
  }
}

export class QualifiedLEAReportData extends ReportData {

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "QualifiedLEAReport"
    rd.parameters = [];
    rd.hasExcel = true;

    return rd;
  }
}




