<div class="toolbar" style="display: flex;justify-content: space-between;">
  <div style="display: flex;align-items: center;">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [routerLink]="['/']" class="toolbar-logo" style="display:flex; align-items: center;">
      <img src="../../../assets/img/FCMAT_short.png" style="height: 50px;" />
      <div class="dct-heading" >LEA Fiscal Status Data Collection System</div>
    </div>

  </div>

  <div style="display: flex; flex-direction: row;">
  
    <!--<fury-toolbar-notifications class="toolbar-button"
                                [numNotifications]="(_authenticationService.user$ | async).numNotifications"
                                *ngIf="(_authenticationService.user$ | async).isInternal"></fury-toolbar-notifications>-->

    <fury-toolbar-user class="toolbar-button"
                       [user]="_authenticationService.user$ | async"
                       (userLogout)="userLogout()"></fury-toolbar-user>
   
  </div>
</div>

