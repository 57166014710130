import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from './profile.service';
import { User } from '../../../_models/user.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { ListService } from '../../../_services/lists.service';

@Component({
  selector: 'jms-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form: UntypedFormGroup;
  currentProfile: User = new User();
  error: string = null;

  constructor(private _profileService: ProfileService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private fb: UntypedFormBuilder,
    private _authenticationService: AuthenticationService,
    public _listService: ListService  ) {

    dialogRef.disableClose = true;

    this.form = this.fb.group(new User());
    this.form.get('firstName').setValidators([Validators.required, Validators.maxLength(100)]);
    this.form.get('lastName').setValidators([Validators.required, Validators.maxLength(100)]);
    this.form.get('title').setValidators([Validators.maxLength(100)]);
    this.form.get('ext').setValidators([Validators.maxLength(50)]);
    this.form.get('email').setValidators([
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]);
  }

  ngOnInit() {
    this._profileService.getProfile().subscribe(p => {
      this.currentProfile = p;
      this.fillForm();
    });
  }

  hasError() {
    return this.error;
  }

  fillForm() {
    Object.keys(this.currentProfile).forEach(k => {
      let control = this.form.get(k);
      if (control)
        control.setValue(this.currentProfile[k], { onlySelf: true });
    });
  }

  getFormInfo() {
    this.currentProfile = new User();
    Object.keys(this.currentProfile).forEach(k => {
      let control = this.form.get(k);
      if (control)
        this.currentProfile[k] = control.value;
    });
  }

  save() {
    if (this.form.valid) {
      this.getFormInfo();
      this._profileService.updateProfile(this.currentProfile)
        .subscribe({
          next: () => {
            this._authenticationService.updateUserInfo(this.currentProfile)
            this.dialogRef.close();
          },
          error: error => {
            this.error = error;
          }
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
