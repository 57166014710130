import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lea } from '../_models/lea.model';
import { Lookup } from '../_models/lookup.model';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { FiscalYear } from '../_models/fiscal-year.model';
import { User } from '../_models/user.model';

export const DefaultPageSize = 50;

@Injectable({ providedIn: 'root' })
export class ListService {

  public States = [
    { 'label': 'Alabama', 'value': 'AL' },
    { 'label': 'Alaska', 'value': 'AK' },
    { 'label': 'American Samoa', 'value': 'AS' },
    { 'label': 'Arizona', 'value': 'AZ' },
    { 'label': 'Arkansas', 'value': 'AR' },
    { 'label': 'California', 'value': 'CA' },
    { 'label': 'Colorado', 'value': 'CO' },
    { 'label': 'Connecticut', 'value': 'CT' },
    { 'label': 'Delaware', 'value': 'DE' },
    { 'label': 'Washington DC', 'value': 'DC' },
    { 'label': 'States of Micronesia', 'value': 'FM' },
    { 'label': 'Florida', 'value': 'FL' },
    { 'label': 'Georgia', 'value': 'GA' },
    { 'label': 'Guam', 'value': 'GU' },
    { 'label': 'Hawaii', 'value': 'HI' },
    { 'label': 'Idaho', 'value': 'ID' },
    { 'label': 'Illinois', 'value': 'IL' },
    { 'label': 'Indiana', 'value': 'IN' },
    { 'label': 'Iowa', 'value': 'IA' },
    { 'label': 'Kansas', 'value': 'KS' },
    { 'label': 'Kentucky', 'value': 'KY' },
    { 'label': 'Louisiana', 'value': 'LA' },
    { 'label': 'Maine', 'value': 'ME' },
    { 'label': 'Marshall Islands', 'value': 'MH' },
    { 'label': 'Maryland', 'value': 'MD' },
    { 'label': 'Massachusetts', 'value': 'MA' },
    { 'label': 'Michigan', 'value': 'MI' },
    { 'label': 'Minnesota', 'value': 'MN' },
    { 'label': 'Mississippi', 'value': 'MS' },
    { 'label': 'Missouri', 'value': 'MO' },
    { 'label': 'Montana', 'value': 'MT' }, 
    { 'label': 'Nebraska', 'value': 'NE' },
    { 'label': 'Nevada', 'value': 'NV' },
    { 'label': 'New Hampshire', 'value': 'NH' },
    { 'label': 'New Jersey', 'value': 'NJ' },
    { 'label': 'New Mexico', 'value': 'NM' },
    { 'label': 'New York', 'value': 'NY' },
    { 'label': 'North Carolina', 'value': 'NC' },
    { 'label': 'North Dakota', 'value': 'ND' },
    { 'label': 'Northern Mariana Islands', 'value': 'MP' },
    { 'label': 'Ohio', 'value': 'OH' },
    { 'label': 'Oklahoma', 'value': 'OK' },
    { 'label': 'Oregon', 'value': 'OR' },
    { 'label': 'Palau', 'value': 'PW' },
    { 'label': 'Pennsylvania', 'value': 'PA' },
    { 'label': 'Puerto Rico', 'value': 'PR' },
    { 'label': 'Rhode Island', 'value': 'RI' },
    { 'label': 'South Carolina', 'value': 'SC' },
    { 'label': 'South Dakota', 'value': 'SD' },
    { 'label': 'Tennessee', 'value': 'TN' },
    { 'label': 'Texas', 'value': 'TX' },
    { 'label': 'Utah', 'value': 'UT' },
    { 'label': 'Vermont', 'value': 'VT' },
    { 'label': 'Virgin Islands', 'value': 'VI' },
    { 'label': 'Virginia', 'value': 'VA' },
    { 'label': 'Washington', 'value': 'WA' },
    { 'label': 'West Virginia', 'value': 'WV' },
    { 'label': 'Wisconsin', 'value': 'WI' },
    { 'label': 'Wyoming', 'value': 'WY' }
  ];

  
  private _leas = new BehaviorSubject<Lea[]>(null);
  leas$: Observable<Lea[]> = this._leas.asObservable();

  private _coes = new BehaviorSubject<Lea[]>(null);
  coes$: Observable<Lea[]> = this._coes.asObservable();

  private _userLeas = new BehaviorSubject<Lea[]>(null);
  userLeas$: Observable<Lea[]> = this._userLeas.asObservable();

  private _riskLevels = new BehaviorSubject<Lookup[]>(null);
  riskLevels$: Observable<Lookup[]> = this._riskLevels.asObservable();

  private _budgetStatuses = new BehaviorSubject<Lookup[]>(null);
  budgetStatuses$: Observable<Lookup[]> = this._budgetStatuses.asObservable();

  private _interimStatuses = new BehaviorSubject<Lookup[]>(null);
  interimStatuses$: Observable<Lookup[]> = this._interimStatuses.asObservable();

  private _surveyTypes = new BehaviorSubject<Lookup[]>(null);
  surveyTypes$: Observable<Lookup[]> = this._surveyTypes.asObservable();

  private _lookups = new BehaviorSubject<Lookup[]>(null);
  lookups$: Observable<Lookup[]> = this._lookups.asObservable();

  private _fiscalYears = new BehaviorSubject<FiscalYear[]>(null);
  fiscalYears$: Observable<FiscalYear[]> = this._fiscalYears.asObservable();

  private _users = new BehaviorSubject<User[]>(null);
  users$: Observable<User[]> = this._users.asObservable();

  private _currentFiscalYear = new BehaviorSubject<FiscalYear>(null);
  currentFiscalYear$: Observable<FiscalYear> = this._currentFiscalYear.asObservable();

  constructor(private http: HttpClient) { }

  public getLeaName(leaId: number): Observable<string> {
    return this.leas$.pipe(
      map(list => {
        if (list) {
          var lea = list.find(c => c.leaId == leaId);
          if (!lea) {
            return 'N/A';
          }
          else return lea.name;
        }
        else {
          return 'Loading...';
        }
      }))
  }

  public getLeaCode(leaId: number): Observable<string> {
    return this.leas$.pipe(
      map(list => {
        if (list) {
          var lea = list.find(c => c.leaId == leaId);
          if (!lea) {
            return '';
          }
          else return lea.cdCode?.toString();
        }
        else {
          return '';
        }
      }))
  }


  public getLeas(coeId: number): void {
    if (!this._leas.getValue()) {

      this.http.get<Lea[]>(`${environment.apiUrl}/Helper/GetLeas`)
        .subscribe(list => {
          this._leas.next(list);
          this.filterCOEs();
          this.filterUserLeas(coeId);
        });
    }
    else {
      this.filterUserLeas(coeId);
    }
  }

  public resetLeas() {
    this._leas.next(null);
  }

  private filterCOEs() {
    var list = this._leas.value.filter(c => c.parentId == c.leaId).sort(this.textsort);
    this._coes.next(list);
  }

  private filterUserLeas(coeId: number) {
    if (coeId) {
      var list = this._leas.value.filter(c => c.parentId == coeId).sort(this.textsort);
      this._userLeas.next(list);
      this._leas.next(list);
    }
  }

  public getLookupValue(lookupId: number): Observable<string> {
    return this.lookups$.pipe(
      map(list => {
        if (list) {
          var lookup = list.find(c => c.id == lookupId);
          if (!lookup) {
            return "";
          }
          else return lookup.text;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getLookups(): void {
    if (!this._lookups.getValue()) {

      this.http.get<Lookup[]>(`${environment.apiUrl}/Helper/GetLookups`)
        .subscribe(list => {
          this._lookups.next(list);
          this.getRiskLevels();
          this.getBudgetStatuses();
          this.getInterimStatuses();
          this.getSurveyTypes();
        });
    }
  }

  private getRiskLevels() {
    if (this._lookups.getValue()) {
      var type = this._lookups.value.find(c => c.text == 'Risk Level' && !c.lookupTypeId);
      var list = this._lookups.value.filter(c => c.lookupTypeId == type.id).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this._riskLevels.next(list);
    }
  }

  private getBudgetStatuses() {
    if (this._lookups.getValue()) {
      var type = this._lookups.value.find(c => c.text == 'Budget Response' && !c.lookupTypeId);
      var list = this._lookups.value.filter(c => c.lookupTypeId == type.id).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this._budgetStatuses.next(list);
    }
  }

  private getInterimStatuses() {
    if (this._lookups.getValue()) {
      var type = this._lookups.value.find(c => c.text == 'Interim Response' && !c.lookupTypeId);
      var list = this._lookups.value.filter(c => c.lookupTypeId == type.id).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this._interimStatuses.next(list);
    }
  }

  private getSurveyTypes() {
    if (this._lookups.getValue()) {
      var type = this._lookups.value.find(c => c.text == 'SurveyType' && !c.lookupTypeId);
      var list = this._lookups.value.filter(c => c.lookupTypeId == type.id).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this._surveyTypes.next(list);
    }
  }

  public getYearValue(fiscalYearId: number): Observable<string> {
    return this.fiscalYears$.pipe(
      map(list => {
        if (list) {
          var year = list.find(c => c.fiscalYearId == fiscalYearId);
          if (!year) {
            return "";
          }
          else return year.year;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getFiscalYears(): void {
    if (!this._fiscalYears.getValue()) {

      this.http.get<FiscalYear[]>(`${environment.apiUrl}/Helper/GetFiscalYears`)
        .subscribe((list: FiscalYear[]) => {
          this._fiscalYears.next(list);
          this.getThisFiscalYear(list);
        });
    }
  }

  private getThisFiscalYear(years: FiscalYear[]) {
    var thisYear = new Date().getFullYear();
    if (new Date().getMonth() < 6) thisYear--;
    var thisFiscalYear = years.find(y => y.year.startsWith(thisYear.toString()));
    if (thisFiscalYear) {
      this._currentFiscalYear.next(thisFiscalYear);
    }
  }

  public getCurrentFiscalYearId(): Observable<number> {
    return this.currentFiscalYear$.pipe(
      map(year => {
        if (year) {
          return year.fiscalYearId;
        }
        else {
          return 0;
        }
      }))
  }

  public getMostRecentYear(): Observable<number> {
    return this.fiscalYears$.pipe(
      map(list => {
        if (list && list.length > 0) {
          return list[0].fiscalYearId;
        }
        else {
          return 0;
        }
      }))
  }

  public resetFiscalYears() {
    this._fiscalYears.next(null);
    this.getFiscalYears();
  }

  public getUserName(userId: number): Observable<string> {
    return this.users$.pipe(
      map(list => {
        if (list) {
          var user = list.find(c => c.userId == userId);
          if (!user) {
            return "";
          }
          else return user.name;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getUsers(): void {
    if (!this._users.getValue()) {

      this.http.get<User[]>(`${environment.apiUrl}/User/GetAllUsers`)
        .subscribe(items => {
          let allUsers = [];
          items.forEach(i => {
            var u = new User();
            u.insertData(i);
            allUsers.push(u)
          });
          this._users.next(allUsers);
        });
    }
  }

  private textsort(a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
}
