import { EmailSent } from "./email-sent.model";

export class User {
  userId: number = 0;
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  title: string = '';
  phone: string = '';
  ext: string = '';
  isInternal: boolean = false;
  coeId: number = 0;
  jwtToken?: string = '';
  forcePasswordChange: boolean = false;
  lastLoginDate: Date = null;
  isActive: boolean = true;
  coeName: string = '';
  isCreating: boolean = false;
  emailSent: EmailSent[] = [];

  constructor() { }

  insertData(data) {
    this.userId = data.userId;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.phone = data.phone;
    this.ext = data.ext;
    this.isInternal = data.isInternal;
    this.coeId = data.coeId;
    this.jwtToken = data.jwtToken;
    this.forcePasswordChange = data.forcePasswordChange
    this.lastLoginDate = data.lastLoginDate;
    this.isActive = data.isActive;
    this.coeName = data.coeName;
    this.isCreating = data.isCreating;
    this.emailSent = data.emailSent;
  }

  get name() {
    let name = '';

    if (this.firstName && this.lastName) {
      name = this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      name = this.firstName;
    } else if (this.lastName) {
      name = this.lastName;
    }
    return name;
  }

  get lastFirst() {
    let name = '';

    if (this.firstName && this.lastName) {
      name = this.lastName + ', ' + this.firstName;
    } else if (this.firstName) {
      name = this.firstName;
    } else if (this.lastName) {
      name = this.lastName;
    }
    return name;
  }

  get isCOE() {
    return !this.isInternal;
  }

}

