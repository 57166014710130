<div style="display:flex; justify-content:space-between">
  <div mat-dialog-title>Report Viewer</div>
  <div *ngIf="reportData.options && reportData.options.length > 0" style="display:flex; flex-direction:column;">
    <div><b>Options:</b></div>
    <div *ngIf="reportData.options.length == 1">
      <mat-checkbox [(ngModel)]="reportData.options[0].isChecked" (ngModelChange)="refreshReport()" name="repOption" ngDefaultControl>{{reportData.options[0].label}}</mat-checkbox>
    </div>
  </div>
  <!--If more than one option then make a radio button-->
  <div *ngIf="reportData.hasExcel">
    <button mat-button (click)="downloadExcel()">EXCEL DOWNLOAD</button>
  </div>
</div>
<mat-dialog-content>
  <ngx-extended-pdf-viewer [src]="report" 
                           useBrowserLocale="true"
                           [showBookmarkButton]="false"
                           [showOpenFileButton]="false"
                           *ngIf="isReady else loading"></ngx-extended-pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button mat-button (click)="close()">CLOSE</button>
</mat-dialog-actions>

<ng-template #loading>
  <div style="width: 40em;">Loading...</div>
</ng-template>
