import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user.model';
import { ProfileComponent } from '../../../pages/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../../pages/authentication/change-password/change-password.component';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  @Input() user: User;
  @Output() userLogout = new EventEmitter();
  isOpen: boolean;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  updateProfile() {
    this.dialog.open(ProfileComponent).afterClosed().subscribe();
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent).afterClosed().subscribe();
  }

  logout() {
    this.userLogout.emit();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

}
