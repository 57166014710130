import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ReportViewerComponent } from './report-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ReportsService } from './reports.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxExtendedPdfViewerModule
    ],
    providers: [ReportsService],
    declarations: [ReportViewerComponent],
    exports: [ReportViewerComponent]
})
export class ReportViewerModule {
}
