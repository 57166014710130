<div mat-dialog-title>My Profile</div>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <div style="display: flex; flex-direction: column; flex: 1;">

      <div class="jms-error-report" *ngIf="hasError()" style="margin-bottom:5px;">
        Error updating profile: {{error}}
      </div>

      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput required formControlName="firstName">
        <mat-error *ngIf="form.get('firstName').hasError('maxlength')">Max length is 100 characters</mat-error>
        <mat-error *ngIf="form.get('firstName').hasError('required')">Please enter a first name</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput required formControlName="lastName">
        <mat-error *ngIf="form.get('lastName').hasError('maxlength')">Max length is 100 characters</mat-error>
        <mat-error *ngIf="form.get('lastName').hasError('required')">Please enter a last name</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="form.get('title').hasError('maxlength')">Max length is 100 characters</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" mask='(000) 000-0000'>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Ext</mat-label>
        <input matInput formControlName="ext">
        <mat-error *ngIf="form.get('ext').hasError('maxlength')">Max length is 50 characters</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput required formControlName="email">
        <mat-error *ngIf="form.get('email').errors">Please enter a valid email address</mat-error>
      </mat-form-field>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button mat-button (click)="cancel(); $event.preventDefault()">CANCEL</button>
    <button mat-flat-button [disabled]="!form.valid" color="accent">UPDATE PROFILE</button>
  </mat-dialog-actions>
</form>
